<template>
    <section>
        <div class="row mx-0">
            <!-- <menu-categorias v-show="viewMenuCategorias" /> -->
            <div class="col my-3">
                <div class="row d-md-none d-lg-none">
                    <div class="col search-input">
                        <el-input v-model="buscador" prefix-icon="icon-search" size="small" placeholder="Buscar producto" class="br-20" @input="buscarProducto" />
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-6 f-28 text-general f-600">
                        <p>Resultados de la búsqueda "{{ buscar }}" </p>
                        <div class="like-bar mb-2" />
                    </div>

                    <div class="col-6">
                        <div class="row my-4">
                            <p class="mt-2">Ordenar por</p>
                            <div class="col-8">
                                <el-select v-model="ordenar" placeholder="Opciones" @change="productosFiltrados">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 overflow-auto custom-scroll" style="height:calc(100vh - 218px)">
                    <div v-show="productos.length == 0" class="a-center j-center text-center w-100 mt-5">
                        <img :src="funImagenGeneral(45)" class="obj-cover " alt="" width="350" height="350" />
                    </div>
                    <div v-for="(data,key) in productos" :key="key">
                        <card-producto :producto="data" :promocion="data.data_promocion" class="mx-xl-4 mx-lg-3 mx-md-3 mx-sm-2 mx-2" />
                    </div>
                </div>
            </div>
        </div>
        <section-footer />
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Tienda from '~/services/tienda/tienda'
export default {
    components: {
        // menuCategorias:()=> import('../categoria/components/menuCategorias.vue'),
    },
    data(){
        return{
            options: [{
                value: 1,
                label: 'Relevancia'
            }, {
                value: 2,
                label: 'Nombre, Z a A'
            }, {
                value: 3,
                label: 'Nombre, A a Z'
            }, {
                value: 4,
                label: 'Precio, Mayor a menor'
            }, {
                value: 5,
                label: 'Precio, Menor a mayor'
            }],
            ordenar: '',
            productos: [],
            buscador:''
        }
    },
    computed: {
        buscar(){
            return this.$route.params.query
        },
        ...mapGetters({
            viewMenuCategorias:'home/viewMenuCategorias'
        })
    },
    watch: {
        buscar(value){
            if (value){
                this.buscarProductos()                
            }
        }
    },
    mounted(){
        this.buscarProductos()
    },
    methods: {
        async buscarProductos(){
            try{
                const params = {
                    buscar: this.$route.params.query
                };
                const { data } = await Tienda.getProductosBuscar(params);
                this.productos = data?.productos               

            } catch (e){
                this.errorCatch(e)
            }    
        },
        productosFiltrados(value){
            switch (value){
            case 1:
                this.productos = _.orderBy(this.productos, 'cant_ventas','desc')
                break;
            case 2:
                this.productos = _.orderBy(this.productos, 'nombre','desc')
                break;
            case 3:
                this.productos = _.orderBy(this.productos, 'nombre','asc')
                break;
            case 4:
                this.productos = _.orderBy(this.productos, 'precio','desc')
                break;
            default:
                this.productos = _.orderBy(this.productos, 'precio','asc')
                break;
            }
        },
        async buscarProducto(){
            const busqueda = async() => {
                if (this.buscador.length > 2){
                    this.$router.push({name: 'home.tienda.busqueda', params: { query: this.buscador } })                    
                } else if(this.buscador == ""){
                    this.$router.push({name: 'home.tienda'})                    
                }
            }
            await this.delay(busqueda)
        },
    }
}
</script>