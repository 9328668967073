import axios from 'axios';

const API = 'productos';

const ENDPOINTS = {
    getProductosBuscar(params){
        return axios.get(`${API}/tienda-buscar`, {params})
    },
};

export default ENDPOINTS;